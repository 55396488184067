import Button from "./Button";

const SystemSelector = ({setFrom, setTo, from, to}) => {
    return (
        <>
            <h2 className="font-bold text-xl">Select a system:</h2>
            <div className="flex items-center gap-4 flex-wrap">
                <Button
                    onClick={() => {
                        setFrom("us customary");
                        setTo("imperial");
                    }}
                    active={from === "us customary" && to === "imperial"}
                >
                    Us customary units - Imperial
                </Button>
                <Button
                    onClick={() => {
                        setFrom("metric");
                        setTo("us customary");
                    }}
                    active={from === "metric" && to === "us customary"}
                >
                    metric - US customary units
                </Button>
                <Button
                    onClick={() => {
                        setFrom("metric");
                        setTo("imperial");
                    }}
                    active={from === "metric" && to === "imperial"}
                >
                    metric - Imperial
                </Button>
                <Button
                    onClick={() => {
                        setFrom("imperial");
                        setTo("metric");
                    }}
                    active={from === "imperial" && to === "metric"}
                >
                    Imperial - metric
                </Button>
            </div>
        </>
    );
};

export default SystemSelector;
