import React from "react";

const Button = (props) => {
    return (
        <button
            className={`rounded-full text-sm font-medium py-2 px-5 ${
                props.active
                    ? "text-white bg-orange-600"
                    : "border-2 border-orange-600 bg-white text-orange-600"
            } ${props.className}`}
            onClick={props.onClick}
            type={props.type}
        >
            {props.children}
        </button>
    );
};

Button.defaultProps = {
    children: "Button Component",
    onClick: undefined,
    active: false,
    type: "button"
};

export default Button;
