import React from "react";

const Box = (props) => {
    return (
        <div className={`rounded-2xl px-5 py-7 bg-neutral-100 ${props.className}`}>
            {props.children}
        </div>
    );
};

export default Box;
