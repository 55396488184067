import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Dropdown = (props) => {
    return (
        <div className={`relative ${props.className}`}>
            <select
                className={`rounded-full bg-white shadow-md p-5 px-6 pr-14 font-normal text-xl appearance-none w-full`}
                onChange={(e) => {
                    props.setValue(e.target.value);
                }}
                value={props.value}
            >
                <option value="us customary">US customary units</option>
                <option value="imperial">Imperial</option>
                <option value="metric">Metric</option>
            </select>

            <KeyboardArrowDownIcon className="absolute top-1/2 -translate-y-1/2 right-4" />
        </div>
    );
};

export default Dropdown;
