/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import MenuBar from "./Components/MenuBar";
import SystemSelector from "./Components/SystemSelector";
import Box from "./Components/Box";
import Dropdown from "./Components/Dropdown";
import Button from "./Components/Button";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import InfoIcon from "@mui/icons-material/Info";

const App = () => {
    const inputFile = useRef();
    const fileForm = useRef();

    const fromValueRef = useRef("");
    const toValueRef = useRef("");

    const [from, setFrom] = useState("us customary");
    const [to, setTo] = useState("imperial");

    const [fromValue, setFromValue] = useState("");
    const [toValue, setToValue] = useState("");

    const lastValueChange = useState();

    useEffect(() => {
        fetch("/convert_text", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                text: fromValue,
                from: from,
                to: to,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                setToValue(data.converted);
                toValueRef.current = data.converted;
                lastValueChange.current = Date.now() / 1000;
            });
    }, [fromValue, from, to]);

    useEffect(() => {
        setInterval(() => {
            fetch("/store_conversion", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    original: fromValueRef.current,
                    converted: toValueRef.current,
                }),
            });
        }, 6000);
    }, []);

    return (
        <>
            <MenuBar />
            <div className="flex justify-center mt-14 mx-9">
                <div className="flex-1 max-w-7xl">
                    <div>
                        <h1 className="font-medium text-2xl">
                            Measurement Conversion
                        </h1>
                        <p className="mt-2 max-w-3xl">
                            Paste, type or upload your text below to
                            automatically convert the measurements into the
                            measurement system of your preference
                        </p>
                    </div>
                    <div className="mt-7 flex gap-10 items-center">
                        <SystemSelector
                            to={to}
                            from={from}
                            setTo={setTo}
                            setFrom={setFrom}
                        />
                    </div>
                    <div className="mt-7 flex gap-8 relative">
                        <button
                            className="absolute rounded-full h-16 w-16 bg-orange-600 left-1/2 -translate-x-1/2 top-7 z-10"
                            onClick={() => {
                                setFrom(to);
                                setTo(from);
                            }}
                        >
                            <CompareArrowsIcon
                                sx={{ color: "white", fontSize: 42 }}
                            />
                        </button>

                        <Box className="flex-1">
                            <div className="flex gap-7 items-center justify-between">
                                <h3 className="text-neutral-600 text-lg">
                                    From
                                </h3>

                                <Dropdown
                                    setValue={setFrom}
                                    value={from}
                                    className="flex-1"
                                />
                            </div>

                            <textarea
                                placeholder='Paste or write some text here, like "3 cups of milk and 1/4 pound butter" or "My 450 sq ft apartment is 2 miles away" or "the engine temperature exceeded 250°F as the car reached 110 mph".'
                                className="overflow-hidden resize-none w-full h-44 bg-transparent outline-none mt-7"
                                value={fromValue}
                                onChange={(e) => {
                                    setFromValue(e.target.value);
                                    fromValueRef.current = e.target.value;
                                }}
                            ></textarea>

                            <hr className="border-neutral-300 border-0 border-t-[0.12rem]" />
                            <form
                                className="mt-5"
                                action={`/convert_spreadsheet?from=${from}&to=${to}`}
                                method="POST"
                                encType="multipart/form-data"
                                ref={fileForm}
                            >
                                <h1 className="font-bold text-xl">
                                    Upload your documents here!
                                </h1>

                                <Button
                                    className="mt-5"
                                    type="button"
                                    onClick={() => {
                                        inputFile.current.click();
                                    }}
                                >
                                    Upload File
                                </Button>
                                <input
                                    type="file"
                                    name="file"
                                    ref={inputFile}
                                    className="hidden"
                                    onChange={() => {
                                        fileForm.current.submit();
                                    }}
                                />
                            </form>
                        </Box>
                        <Box className="flex-1">
                            <div className="flex gap-7 items-center justify-between">
                                <h3 className="text-neutral-600 text-lg">To</h3>

                                <Dropdown
                                    setValue={setTo}
                                    value={to}
                                    className="flex-1"
                                />
                            </div>

                            <textarea
                                placeholder="The converted unit will be shown here."
                                className="overflow-hidden resize-none w-full h-44 bg-transparent outline-none mt-7"
                                value={toValue}
                                readOnly
                            ></textarea>

                            <hr className="border-neutral-300 border-0 border-t-[0.12rem]" />

                            <div className="mt-5 flex flex-col justify-center">
                                <div>
                                    <h1 className="font-bold text-xl">
                                        Please rate this conversion
                                    </h1>
                                    <div className="bg-white border-2 rounded-full flex child:flex-1 max-w-[7rem] mt-5">
                                        <button className="bg-neutral-200 rounded-full py-1">
                                            <ThumbUpAltIcon
                                                sx={{ color: "#EC4D37" }}
                                            />
                                        </button>
                                        <button className="rounded-full py-1">
                                            <ThumbDownAltIcon
                                                sx={{ color: "#EC4D37" }}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
            <div className="mt-10 mx-[12.5rem] flex gap-4">
                <InfoIcon />
                <p className="max-w-md">
                    Note: imperimetric assumes 'ounces'/'oz' as weight ounces
                    and 'fluid ounces'/'floz'/'fl. oz' as fluid ounces.{" "}
                    <a href="/" className="text-orange-600 underline">More unit info</a>
                </p>
            </div>
        </>
    );
};

export default App;
