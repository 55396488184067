import React from "react";
import logo from "../logo.png";

const Head = () => {
    return (
        <div className="bg-white w-screen h-16 shadow-md flex items-center justify-center">
            <div className="flex-1 max-w-7xl mx-9">
                <img src={logo} alt="logo" className="w-48" />
            </div>
        </div>
    );
};

export default Head;
